import { CssBaseline, Drawer } from "@material-ui/core";
import { Tooltip } from "@mui/material";
// import lender from "../../assets/img/Lender_Icon.svg";
// import roopya_network from "../../assets/img/roopya_networks.svg";
import business_list from "../../assets/img/business-list.svg";
import businessRule from "../../assets/img/businessRules.svg";
import crm from "../../assets/img/crm.svg";
import customers from "../../assets/img/customers.svg";
import dashboard from "../../assets/img/dashboard.svg";
import roopya_network from "../../assets/img/networking.svg";
import partners from "../../assets/img/partners.svg";
import apps from "../../assets/img/play-store.svg";
import setting from "../../assets/img/settings.svg";
import Master from "../../assets/img/Administrative Tools-595b40b75ba036ed117d78be.svg";
import Organization from "../../assets/img/organization.png"

// import "./SideNav.css";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import clsx from "clsx";
import { default as React } from "react";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import permissionKeys from "../../constants/Permissions/permissionKeys";
import routes from "../../constants/Routes/routes";
import useStyles from "./SideNav.style";
import style from "./Sidenav.module.css";

export default function SideNav(props) {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const sidebarPermissionKeys = useSelector((state) => state.auth.permissionKeys.sidebar);
    const { ondrowerWidthChange } = props;
    const panCard = useSelector((state) => state.auth.employee.panCard);
    // const panCard = false;

    const handleDrawerOpen = () => {
        setOpen(!open);
        ondrowerWidthChange();
    };

    return (
        <>
            <div className={classes.root}>
                <CssBaseline />
                <Drawer
                    variant="permanent"
                    elevation={7}
                    className={clsx(classes.drawer, {
                        [classes.drawerOpen]: open,
                        [classes.drawerClose]: !open,
                    })}
                    classes={{
                        paper: clsx({
                            [classes.drawerOpen]: open,
                            [classes.drawerClose]: !open,
                        }),
                    }}
                >
                    <aside className={style.aside}>
                        <div className={style.sidebar}>
                            {/*  Dashboard */}
                            {sidebarPermissionKeys[permissionKeys.sidebars.DASHBOARD_SIDEBAR] && (
                                <NavLink
                                    to={routes.privatePages.DashboardIndexPage.getUrl()}
                                    className={style.linkStyle}
                                >
                                    <Tooltip
                                        title="Dashboard"
                                        aria-label="dashboard"
                                        placement="right"
                                        className={classes.tooltipSize}
                                    >
                                        <img src={dashboard} className={style.image_size} alt="" />
                                    </Tooltip>
                                    {open && <h3>Dashboard</h3>}
                                </NavLink>
                            )}

                            {/* Customers */}
                            {sidebarPermissionKeys[permissionKeys.sidebars.CUSTOMERS_SIDEBAR] && (
                                <NavLink
                                    to={routes.privatePages.CustomersIndexPage.getUrl()}
                                    className={style.linkStyle}
                                >
                                    <Tooltip
                                        title="Customer Master"
                                        aria-label="customer Master"
                                        placement="right"
                                        className={classes.tooltipSize}
                                    >
                                        <img className={style.image_size} src={customers} alt="" />
                                    </Tooltip>
                                    {open && <h3>Customer Master</h3>}
                                </NavLink>
                            )}

                            {/* {sidebarPermissionKeys[permissionKeys.sidebars.BUSINESS_SIDEBAR] && (
                                <NavLink to={routes.privatePages.BusinessListPage.getUrl()} className={style.linkStyle}>
                                    <Tooltip
                                        title="Business List"
                                        aria-label="Business List"
                                        placement="right"
                                        className={classes.tooltipSize}
                                    >
                                        <img className={style.image_icon} src={business_list} alt="" />
                                    </Tooltip>
                                    {open && <h3>Business List</h3>}
                                </NavLink>
                            )} */}

                            {/* CRM */}
                            {sidebarPermissionKeys[permissionKeys.sidebars.CRM_SIDEBAR] && (
                                <NavLink
                                    to={routes.privatePages.PipelineIndexPage.getUrl()}
                                    className={style.linkStyle}
                                >
                                    <Tooltip
                                        title="CRM"
                                        aria-label="crm"
                                        placement="right"
                                        className={classes.tooltipSize}
                                    >
                                        <img className={style.image_size} src={crm} alt="" />
                                    </Tooltip>
                                    {open && <h3>CRM</h3>}
                                </NavLink>
                            )}

                            {/* Roopya network */}
                            {sidebarPermissionKeys[permissionKeys.sidebars.ROOPYA_NETWORK_SIDEBAR] && (
                                <NavLink
                                    to={routes.privatePages.RoopyaNetworkIndexPage.getUrl()}
                                    className={style.linkStyle}
                                >
                                    <Tooltip
                                        title="Roopya Network"
                                        aria-label="Roopya Network"
                                        placement="right"
                                        className={classes.tooltipSize}
                                    >
                                        <img className={style.image_icon} src={roopya_network} alt="" />
                                    </Tooltip>
                                    {open && <h3>Roopya Network</h3>}
                                </NavLink>
                            )}

                            {/* Bre */}
                            {sidebarPermissionKeys[permissionKeys.sidebars.BRE_SIDEBBAR] && (
                                <NavLink to={routes.privatePages.BreIndexPage.getUrl()} className={style.linkStyle}>
                                    <Tooltip
                                        title="Business Rules"
                                        aria-label="business rules"
                                        placement="right"
                                        className={classes.tooltipSize}
                                    >
                                        <img className={style.image_size} src={businessRule} alt="" />
                                    </Tooltip>
                                    {open && <h3>Business Rules</h3>}
                                </NavLink>
                            )}

                            {/* Partner */}
                            {sidebarPermissionKeys[permissionKeys.sidebars.PARTNERS_SIDEBAR] && (
                                <NavLink to={routes.privatePages.PartnerIndexPage.getUrl()} className={style.linkStyle}>
                                    <Tooltip
                                        title="Partners"
                                        aria-label="partners"
                                        placement="right"
                                        className={classes.tooltipSize}
                                    >
                                        <img className={style.image_size} src={partners} alt="" />
                                    </Tooltip>
                                    {open && <h3>Partners</h3>}
                                </NavLink>
                            )}

                            {/* Settings */}
                            {sidebarPermissionKeys[permissionKeys.sidebars.SETTINGS_SIDEBAR] && (
                                <NavLink
                                    to={routes.privatePages.SettingsIndexPage.getUrl()}
                                    className={style.linkStyle}
                                >
                                    <Tooltip
                                        title="Settings"
                                        aria-label="settings"
                                        placement="right"
                                        className={classes.tooltipSize}
                                    >
                                        <img className={style.image_size} src={setting} alt="" />
                                    </Tooltip>
                                    {open && <h3>Settings</h3>}
                                </NavLink>
                            )}

                            {/* Marketplace */}
                            {sidebarPermissionKeys[permissionKeys.sidebars.APPS_SIDEBAR] && (
                                <NavLink to={routes.privatePages.AppsIndexPage.getUrl()} className={style.linkStyle}>
                                    <Tooltip
                                        title="Apps"
                                        aria-label="apps"
                                        placement="right"
                                        className={classes.tooltipSize}
                                    >
                                        <img
                                            className={`${style.image_size} ${style.marketplace_image_size}`}
                                            src={apps}
                                            alt=""
                                        />
                                    </Tooltip>
                                    {open && <h3>Apps</h3>}
                                </NavLink>
                            )}

                            {/* Master */}
                            {/* {sidebarPermissionKeys[permissionKeys.sidebars.APPS_SIDEBAR] && ( */}
                            <NavLink to={routes.privatePages.MasterIndexPage.getUrl()} className={style.linkStyle}>
                                <Tooltip
                                    title="Master"
                                    aria-label="Master"
                                    placement="right"
                                    className={classes.tooltipSize}
                                >
                                    <img
                                        className={`${style.image_size}`}
                                        style={{ height: "1.6rem" }}
                                        src={Master}
                                        alt=""
                                    />
                                </Tooltip>
                                {open && <h3>Master</h3>}
                            </NavLink>

                            {panCard && <NavLink to={routes.privatePages.MyOrganizationPage.getUrl()} className={style.linkStyle}>
                                <Tooltip
                                    title="My Organization"
                                    aria-label="My Organization"
                                    placement="right"
                                    className={classes.tooltipSize}
                                >
                                    <img
                                        className={`${style.image_size}`}
                                        style={{ height: "1.6rem" }}
                                        src={Organization}
                                        alt=""
                                    />
                                </Tooltip>
                                {open && <h3>My Organization</h3>}
                            </NavLink>}
                            {/* )} */}
                            <ArrowForwardIosIcon
                                onClick={handleDrawerOpen}
                                className={open ? classes.rotatearrowIconStyle : classes.arrowIconStyle}
                                title={open ? "Close Sidebar" : "Open Sidebar"}
                            />
                        </div>
                    </aside>
                </Drawer>
            </div>
        </>
    );
}
