const permissionKeys = {
    sidebars: {
        DASHBOARD_SIDEBAR: "DASHBOARD_SIDEBAR",
        CUSTOMERS_SIDEBAR: "CUSTOMERS_SIDEBAR",
        BUSINESS_SIDEBAR: "BUSINESS_SIDEBAR",
        CRM_SIDEBAR: "CRM_SIDEBAR",
        ROOPYA_NETWORK_SIDEBAR: "ROOPYA_NETWORK_SIDEBAR",
        BRE_SIDEBBAR: "BRE_SIDEBBAR",
        PARTNERS_SIDEBAR: "PARTNERS_SIDEBAR",
        SETTINGS_SIDEBAR: "SETTINGS_SIDEBAR",
        APPS_SIDEBAR: "APPS_SIDEBAR",
    },
    pages: {
        DASHBOARD_INDEX_PAGE: "DASHBOARD_INDEX_PAGE",
        CUSTOMERS_INDEX_PAGE: "CUSTOMERS_INDEX_PAGE",
        BUSINESS_INDEX_PAGE: "BUSINESS_INDEX_PAGE",
        CRM_INDEX_PAGE: "CRM_INDEX_PAGE",
        ROOPYA_NETWORK_INDEX_PAGE: "ROOPYA_NETWORK_INDEX_PAGE",
        BRE_INDEX_PAGE: "BRE_INDEX_PAGE",
        PARTNERS_INDEX_PAGE: "PARTNERS_INDEX_PAGE",
        SETTINGS_INDEX_PAGE: "SETTINGS_INDEX_PAGE",
        APPS_INDEX_PAGE: "APPS_INDEX_PAGE",
        // -----
        LEAD_DETAILS_PAGE: "LEAD_DETAILS_PAGE",
        CUSTOMER_DETAILS_PAGE: "CUSTOMER_DETAILS_PAGE",
        PIPELINE_DETAILS_PAGE: "PIPELINE_DETAILS_PAGE",
        ADD_PIPELINE_PAGE: "ADD_PIPELINE_PAGE",
        BRE_CREATE_PAGE: "BRE_CREATE_PAGE",
        BRE_EDIT_PAGE: "BRE_EDIT_PAGE",
        PARTNER_DETAILS_PAGE: "PARTNER_DETAILS_PAGE",
        GENERATE_SANCTION_LETTER_PAGE: "GENERATE_SANCTION_LETTER_PAGE",
        LOAN_OFFER_AGREEMENT_PAGE: "LOAN_OFFER_AGREEMENT_PAGE",
        BUSINESS_DETAILS_PAGE: "BUSINESS_DETAILS_PAGE",
        CREATE_LEAD_PAGE: "CREATE_LEAD_PAGE",
        BULK_SMS_SENDING_PAGE: "BULK_SMS_SENDING_PAGE",
        CONTACT_API_PAGE: "CONTACT_API_PAGE",
        PIPELINE_LEADS_PAGE: "PIPELINE_LEADS_PAGE",
        PARTNER_PIPELINE_LEADS_PAGE: "PARTNER_PIPELINE_LEADS_PAGE",
        CUSTOMER_CRIF_DETAILS_PAGE: "CUSTOMER_CRIF_DETAILS_PAGE",
        PARTNER_PIPELINE_SOURCE_LINK_PAGE: "PARTNER_PIPELINE_SOURCE_LINK_PAGE",
        SEND_SMS_TO_CUSTOMER_PAGE: "SEND_SMS_TO_CUSTOMER_PAGE",
        BI_REPORTS_PAGE: "BI_REPORTS_PAGE",
        CAM_REPORT_PAGE: "CAM_REPORT_PAGE",
    },
    permissions: {
        DASHBOARD_KPI_CARDS: "DASHBOARD_KPI_CARDS",
        BULK_UPLOAD_BUTTON: "BULK_UPLOAD_BUTTON",
        CUSTOMERS_SETTINGS_BUTTON: "CUSTOMERS_SETTINGS_BUTTON",
    },
    permissionDetails: {},
};

export default permissionKeys;
